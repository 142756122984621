<template>
  <ib-card
    class="py-3 px-3 px-md-4"
    :animation="false"
  >
    <h4 class="m-0 mb-4">
      {{ $t('dialogs.setup.ownership.addDialog.addAllOwnersUntilTotalOwnershipEquals') }}
    </h4>

    <div>
      <el-form>
        <div
          v-for="(owner, index) in owners"
          :key="index"
          class="mb-4"
        >
          <el-row :gutter="15" class="d-flex flex-wrap align-items-center">
            <el-col :span="24" :md="2" class="d-flex justify-content-center align-items-center mb-4 mb-md-0">
              <div class="text-center">
                <ib-color-picker
                  :color="owner.color"
                  @change="owner.color = $event"
                />
              </div>
            </el-col>
            <el-col :span="24" :md="11" class="mb-3 mb-md-0">
              <el-form-item
                class="input-inside-label mb-0"
                :class="{'focused': owner.name || owners[index].focusName}"
                :label="$t('pages.businessGuide.ownership.addDialog.fields.name')"
              >
                <el-input
                  v-model="owners[index].name"
                  placeholder=""
                  @focus="toggleFocus($event, index, 'focusName')"
                  @blur="toggleFocus($event, index, 'focusName')"
                />
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="10" class="mb-3 mb-md-0">
              <div>
                <el-form-item
                  class="input-inside-label mb-0"
                  :class="{'focused': owner.percentage !== null || owners[index].focusPercentage}"
                  :label="$t('pages.businessGuide.ownership.addDialog.fields.share')"
                >
                  <div class="el-input">
                    <currency-input
                      v-model="owners[index].percentage"
                      class="el-input__inner"
                      :distraction-free="false"
                      :value-range="{min: 0}"
                      :precision="2"
                      :currency="{suffix: '%'}"
                      :allow-negative="false"
                      locale="en-US"
                      @focus="toggleFocus($event, index, 'focusPercentage')"
                      @blur="toggleFocus($event, index, 'focusPercentage')"
                    />
                  </div>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="24" :md="1" class="d-flex justify-content-center text-center no-tap-highlight">
              <div class="cursor-pointer" @click="deleteOwner(index)">
                <i class="trash-icon ib-icon-trash text-silver" />
              </div>
            </el-col>
          </el-row>

          <ib-divider class="d-md-none pt-3" block />
        </div>
      </el-form>
    </div>

    <add-new-card
      class="py-2"
      :title="$t('pages.businessGuide.ownership.addOwner')"
      horizontal
      center
      :disabled="isAddOwnerDisabled || !isValidate"
      @click="addOwner"
    />
  </ib-card>
</template>

<script>
import AddNewCard from '@/components/_v2/AddNewCard'
import _cloneDeep from 'lodash/cloneDeep'
import colorPickerColors from '@/data/colorPickerColors'
import { mapGetters } from 'vuex'

const ownerObject = {
  id: null,
  ownership_id: null,
  color: null,
  name: '',
  percentage: null,
  focusName: false,
  focusPercentage: false
}

export default {
  name: 'AddOwnersForm',

  components: {
    AddNewCard
  },

  props: {
    value: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      notificationShow: false,
      nextColorIndex: 0
    }
  },

  computed: {
    ...mapGetters('idea/ownership', ['ownership']),

    owners: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },

    lastColor () {
      return colorPickerColors[colorPickerColors.length - 1]
    },

    sumPercentages () {
      const percentages = this.owners.map(owner => {
        return Number(owner.percentage)
      })

      return percentages.reduce((a, b) => a + b, 0)
    },

    isAddOwnerDisabled () {
      return this.sumPercentages >= 100 || this.owners.length === 10
    },

    isValidate () {
      const names = this.owners.map(owner => owner.name)
      const percentages = this.owners.map(owner => owner.percentage)

      return !(names.some(v => v === '') || percentages.some(v => v === null || v === 0))
    }
  },

  watch: {
    sumPercentages: {
      handler (value) {
        if (value > 100 && !this.notificationShow) {
          this.notificationShow = true
          this.$notify({
            message: this.$t('dialogs.setup.ownership.addDialog.errorSumMessage'),
            type: 'error',
            duration: 0,
            onClose: () => {
              this.notificationShow = false
            }
          })
        }
        if (value === 100 && this.notificationShow) {
          this.notificationShow = false
          this.$notify.closeAll()
        }
        this.$emit('percentages', value)
      },
      immediate: true
    },

    isValidate: {
      handler (value) {
        this.$emit('validate', value)
      },
      immediate: true
    }
  },

  mounted () {
    this.setNextColorIndex()
  },

  methods: {
    addOwner () {
      const owner = _cloneDeep(ownerObject)
      owner.ownership_id = this.ownership.id
      owner.color = colorPickerColors[this.nextColorIndex]
      this.owners.push(owner)

      if (owner.color === this.lastColor) {
        this.nextColorIndex = 0
      } else {
        this.nextColorIndex++
      }
    },

    deleteOwner (index) {
      this.owners.splice(index, 1)

      if (!this.owners.length) {
        this.nextColorIndex = 0

        return
      }
      this.nextColorIndex--
    },

    toggleFocus (event, index, name) {
      this.owners[index][name] = event.type === 'focus'
    },

    setNextColorIndex () {
      this.nextColorIndex = this.owners.length
    }
  }
}
</script>

<style scoped lang="scss">
.trash-icon {
  font-size: 24px;
}
</style>
