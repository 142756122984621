<template>
  <ib-card
    class="owners-share-card d-flex flex-column py-3"
    :animation="false"
  >
    <p v-if="!isEmpty" class="m-0 text-center h4">
      {{ $t('pages.businessGuide.ownership.share') }}
    </p>

    <div>
      <owners-chart />
    </div>
  </ib-card>
</template>

<script>
import OwnersChart from '@/views/Home/StoryMode/SetUp/Ownership/Components/Charts/OwnersChart/OwnersChart'
import { mapGetters } from 'vuex'

export default {
  name: 'ShareCard',

  components: {
    OwnersChart
  },

  computed: {
    ...mapGetters('idea/ownership', ['getOwners']),

    isEmpty () {
      return !this.getOwners.length
    }
  }
}
</script>

<style scoped lang="scss">
.owners-share-card {
  row-gap: 20px;
}
</style>
