<template>
  <el-form class="ownership-types-form">
    <el-form-item
      class="input-inside-label"
      :class="{'select-type': isTypeOther}"
    >
      <ib-select
        v-model="form.type"
        :placeholder="$t('pages.businessGuide.ownership.chooseABusinessStructure')"
        :disabled="!canEdit"
        @change="onOwnershipSave"
      >
        <ib-option
          v-for="item in ownershipTypes"
          :key="item.id"
          :label="$t(item.name)"
          :value="item.id"
        >
          <p class="m-0">
            {{ $t(item.name) }}
          </p>
          <span class="text-silver">
            {{ $t(item.description) }}
          </span>
        </ib-option>
      </ib-select>
    </el-form-item>
    <el-form-item
      v-if="isTypeOther"
      class="input-inside-label"
    >
      <el-input
        v-model="form.otherType"
        maxlength="100"
        :placeholder="$t('pages.businessGuide.ownership.myCompanyType')"
        :disabled="!canEdit"
        @blur="onOwnershipSave"
      />
    </el-form-item>
  </el-form>
</template>

<script>
import IbOption from '@/components/_v2/Select/IbOption'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'

import OwnershipTypesEnum, { OwnershipTypes } from '@/constants/OwnershipTypesEnum'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TypesForm',

  components: {
    IbOption
  },

  mixins: [MixinIdeaRoles],

  data () {
    return {
      types: OwnershipTypesEnum,
      ownershipTypes: OwnershipTypes,
      form: {
        ideaId: this.$store.state.idea.id,
        type: null,
        otherType: null
      }
    }
  },

  computed: {
    ...mapGetters('idea/ownership', ['ownership']),

    isTypeOther () {
      return this.form.type === this.types.OTHER
    }
  },

  watch: {
    'ownership.id' (value) {
      if (value === 0) {
        this.setOwnershipForm({ type: null, otherType: null })
      }
    }
  },

  created () {
    this.setOwnershipForm(this.ownership)

    this.getOwnership()
      .then((response) => {
        this.setOwnershipForm(response.data)
      })
  },

  methods: {
    ...mapActions('idea', [
      'getOwnership'
    ]),
    ...mapActions('idea/ownership', [
      'updateOwnership'
    ]),

    onOwnershipSave () {
      this.updateOwnership(this.form)
    },

    setOwnershipForm (data) {
      if (data) {
        this.form.type = data.type
        this.form.otherType = data.otherType
      }
    }
  }
}
</script>

<style scoped lang="scss">
.ownership-types-form {
  .select-type {
    margin-bottom: 6px;
  }

  ::v-deep {
    .el-input {
      .el-input__inner {
        background-color: white !important;
        border: none;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 0 2px 0 rgba(0, 0, 0, 0.12);
        opacity: 1;
        height: 50px;
        padding-top: 20px;
        color: $color-navy !important;

        &:focus {
          color: unset;
        }
      }

      .el-input__suffix {
        top: 0;
      }
    }

    .el-select-dropdown {
      left: 0 !important;
    }
  }
}
</style>
