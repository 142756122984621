<template>
  <ib-card
    class="owners-list-card d-flex flex-column pt-3"
    :animation="false"
  >
    <div
      class="header d-flex justify-content-between align-items-center"
      :class="{'header-padding': ownersLength > 3}"
    >
      <h4 class="text-navy m-0">
        {{ $t('pages.businessGuide.ownership.owners') }}
      </h4>

      <div
        v-if="canEdit"
        class="no-tap-highlight cursor-pointer"
        @click="edit"
      >
        <i class="edit-icon ib-icon-edit text-silver" />
      </div>
    </div>

    <owners-list-table :owners="getOwners" />
  </ib-card>
</template>

<script>
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import OwnersListTable from '@/views/Home/StoryMode/SetUp/Ownership/Components/OwnersList/OwnersListTable'
import { mapGetters } from 'vuex'

export default {
  name: 'OwnersListCard',

  components: {
    OwnersListTable
  },

  mixins: [MixinIdeaRoles],

  computed: {
    ...mapGetters('idea/ownership', ['getOwners']),

    ownersLength () {
      return this.getOwners.length
    }
  },

  methods: {
    edit () {
      if (!this.canEdit) return
      this.$emit('edit')
    }
  }
}
</script>

<style scoped lang="scss">
.owners-list-card {
  row-gap: 35px;
  padding: 0 12px 0;

  .header {
    padding: 0 22px 0 32px;

    &.header-padding {
      padding: 0 37px 0 32px;
    }

    .edit-icon {
      font-size: 24px;
    }
  }
}
</style>
